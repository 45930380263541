// FIELDS OF TABLEBANKACCOUNT
export default[
    {
        key: "name",
        label: "Name",
        sortable: false,
    },
    {
        key: "number",
        label: "Phone Number",
        sortable: false,
    },
    {
        key: "created_at",
        label: "Created At",
        sortable: false,
    },
    {
        key: "actions",
        thClass: "text-center",
        tdClass: "text-center",
    },

]