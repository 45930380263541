<template>
  <div>
    <filter-slot
      :filter="Filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="paginate.startPage"
      :to-page="paginate.toPage"
      @reload="$refs['AttorneyManagementList'].refresh()"
    >
      <template #buttons>
        <div class="ml-2">
          <b-button variant="primary" @click="openModalAttorneyManagment()">
            <feather-icon icon="PlusIcon" size="15" class="mr-50" />NEW ATTORNEY
          </b-button>
        </div>
      </template>
      <b-table
        ref="AttorneyManagementList"
        slot="table"
        class="blue-scrollbar"
        primary-key="id"
        empty-text="No matching records found"
        responsive="md"
        sticky-header="50vh"
        small
        show-empty
        no-provider-filtering
        :items="myProvider"
        :fields="fields"
        :per-page="paginate.perPage"
        :current-page="paginate.currentPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(name)="data">
          <change-sms
            :sms-data="data.item.name"
            :TextLength="50"
          ></change-sms>
        </template>
        <template #cell(actions)="data">
          <feather-icon
            v-b-tooltip.hover.left="'Edit attorney'"
            icon="EditIcon"
            size="18"
            class="cursor-pointer text-warning mr-1"
            @click="openModalEditAttorneyManagment(data.item.id)"
          />
          <feather-icon
            v-b-tooltip.hover.left="data.item.status === 1 ? 'Delete attorney' : 'Activate attorney'"
            :icon="data.item.status === 1 ? 'TrashIcon' : 'CheckCircleIcon'"
            size="18"
            :class="data.item.status === 1 ? 'cursor-pointer text-danger' : 'cursor-pointer text-success'"
            @click="deleteAttorneyManagment(data.item)"
          />
        </template>
      </b-table>
    </filter-slot>
    <modal-attorney-management
      v-if="showModal"
      :attorneyId="attorneyId"
      @close="showModal = false"
      @closing="$refs['AttorneyManagementList'].refresh()"
    />
  </div>
</template>
  <script>
import { mapGetters } from "vuex";
import ModalAttorneyManagement from "@/views/debt-solution/views/settings/views/manageCI/components/modal/ModalAttorneyManagement.vue";
import ManageAttorneyInfoFields from "@/views/debt-solution/views/settings/views/manageCI/data/manage-attorney-info.fields.js";
import SettingsServices from "@/views/debt-solution/views/settings/views/manageCI/services/Settings.service.js";
import Filters from "@/views/debt-solution/views/settings/views/manageCI/data/filters.attorney.js";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";
export default {
  components: { ModalAttorneyManagement, ChangeSms },
  data() {
    return {
      showModal: false,
      attorneyId: null,
      fields: ManageAttorneyInfoFields,
      Filters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Attorney Name...",
        model: "",
      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
        startPage: 0,
        toPage: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async myProvider(ctx) {
      this.addPreloader();
      try {
        const { data } = await SettingsServices.getAttorneyManageCI({
          perPage: this.paginate.perPage,
          npage: this.paginate.currentPage,
          name: this.filterPrincipal.model,
          status: this.Filters[0].model,
        });
        this.totalRows = data.total;
        this.paginate.startPage = data.from ? data.from : 0;
        this.paginate.toPage = data.to ? data.to : 0;
        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    openModalAttorneyManagment() {
      this.showModal = true;
      this.attorneyId = null;
    },

    openModalEditAttorneyManagment(item) {
      this.showModal = true;
      this.attorneyId = item;
    },

    async deleteAttorneyManagment(item) {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          const { data } = await SettingsServices.saveNewAttorneyManagement({
            id: item.id,
            name: item.name,
            number: item.number,
            status: item.status === 1 ? 0 : 1,
          });
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
          );
          this.$refs["AttorneyManagementList"].refresh();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
    