export default [
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "STATUS",
    placeholder: "Select status",
    model: null,
    options: [
      { label: "Active", value: 1 },
      { label: "Deleted", value: 0 },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
  }
];
